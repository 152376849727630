

import community_home_content from "./api_community_home_content.auto"

import news_coupon from "./api_news_coupon.auto"

import wxamp_user_group from "./api_wxamp_user_group.auto"

import wxamp_user_sign from "./api_wxamp_user_sign.auto"

import community_event_wxamp_user from "./api_community_event_wxamp_user.auto"

import community_event_reservation_date from "./api_community_event_reservation_date.auto"

import community_event_reservation_time from "./api_community_event_reservation_time.auto"

import community_event from "./api_community_event.auto"

import community_event_reservation from "./api_community_event_reservation.auto"

import community_home_community_event from "./api_community_home_community_event.auto"

import community_home from "./api_community_home.auto"

import news_header from "./api_news_header.auto"

import news_user_log from "./api_news_user_log.auto"

import role_route from "./api_role_route.auto"

import role from "./api_role.auto"

import user_roles from "./api_user_roles.auto"

import user from "./api_user.auto"

import wxamp_user_group_user from "./api_wxamp_user_group_user.auto"

import wxamp_user from "./api_wxamp_user.auto"

import ali_oss from "./api_ali_oss.auto"

import community_event_reservation_time_template from "./api_community_event_reservation_time_template.auto"

import wxamp_user_point from "./api_wxamp_user_point.auto"

import news from "./api_news.auto"

import news_coupon_user_log from "./api_news_coupon_user_log.auto"

import wxamp from "./api_wxamp.auto"

import file from "./api_file.auto"

import model from "./api_model.auto"

import dictionary from "./api_dictionary.auto"

import home from "./api_home.auto"

import route from "./api_route.auto"

import community_event_sign from "./api_community_event_sign.auto"

import coupon from "./api_coupon.auto"


export default {
   community_home_content,
   news_coupon,
   wxamp_user_group,
   wxamp_user_sign,
   community_event_wxamp_user,
   community_event_reservation_date,
   community_event_reservation_time,
   community_event,
   community_event_reservation,
   community_home_community_event,
   community_home,
   news_header,
   news_user_log,
   role_route,
   role,
   user_roles,
   user,
   wxamp_user_group_user,
   wxamp_user,
   ali_oss,
   community_event_reservation_time_template,
   wxamp_user_point,
   news,
   news_coupon_user_log,
   wxamp,
   file,
   model,
   dictionary,
   home,
   route,
   community_event_sign,
   coupon,
}

