import auto from "./_api.auto"

import login from "./login";

import route from "./api_route";

import wxamp from "./api_wxamp";

import file from "./api_file";


import community_home from "./api_community_home";

import community_home_content from "./api_community_home_content";

import community_event_sign from "./api_community_event_sign";

import community_event from "./api_community_event";

import wxamp_user from "./api_wxamp_user";

import community_event_reservation_time_template from "./api_community_event_reservation_time_template";

import community_event_reservation from "./api_community_event_reservation";

import sdata from "./api_sdata";

// import community_event_reservation_date from "./api_community_event_reservation_date";

export default {
    sdata,
    file,
    wxamp,
    login,
    auto,
    route,
    community_home,
    community_home_content,
    community_event,
    community_event_sign,
    community_event_reservation_time_template,
    community_event_reservation,
    // community_event_reservation_date,
    wxamp_user,
}
