import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { CommunityEventReservationTimeTemplate } from './model.type';

const base_path = '/community_event_reservation_time_template';

// get by community_event_id
const getByCommunityEventID = (community_event_id : string) => {
    return http.get(`${base_path}/get_by_community_event_id?community_event_id=${community_event_id}`)
}


export default {
    getByCommunityEventID,
};
