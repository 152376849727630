import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { CommunityEventReservation } from './model.type';

const base_path = '/community_event_reservation';

const ListByCommunityEventID = (q: PageQuery, community_event_id = '', community_event_reservation_time_id = '0') => {
    return http.get(`${base_path}/list_by_community_event_id?community_event_id=${community_event_id}&${http.pageToUrlParams(q)}&community_event_reservation_time_id=${community_event_reservation_time_id}`)
}

const ExportByCommunityEventID = (community_event_id = '') => {
    // window.open(`${import.meta.env.VITE_APP_BASE_API || ''}${base_path}/export_by_community_event_id?community_event_id=${community_event_id}`, '_blank')
    return http.get(`${base_path}/export_by_community_event_id?community_event_id=${community_event_id}`)
}

// 通过审核
const Pass = (community_event_reservation_id: string, reason: string = '管理员核销') => {
    return http.post(`${base_path}/pass?community_event_reservation_id=${community_event_reservation_id}&reason=${reason}`,{})
}

// 拒绝审核
const Reject = (community_event_reservation_id: string, reason: string = '管理员核销') => {
    return http.post(`${base_path}/reject?community_event_reservation_id=${community_event_reservation_id}&reason=${reason}`,{})
}

const ListByGtnAndCommunityEventID = (q: PageQuery,community_event_id = 0) => {
    return http.get(`${base_path}/list_by_gtn_and_community_event_id?${http.pageToUrlParams(q)}&community_event_id=${community_event_id}`)
}



// TimeListByCommunityEventReservationDateID
const TimeListByCommunityEventReservationDateID = (community_event_reservation_date_id = 0) => {
    return http.get(`${base_path}/time_list_by_community_event_reservation_date_id?community_event_reservation_date_id=${community_event_reservation_date_id}`)
}

// ListByCommunityEventReservationTimeID
const ListByCommunityEventReservationTimeID = (q: PageQuery, community_event_reservation_time_id = 0) => {
    return http.get(`${base_path}/list_by_community_event_reservation_time_id?${http.pageToUrlParams(q)}&community_event_reservation_time_id=${community_event_reservation_time_id}`)
}


export default {
    TimeListByCommunityEventReservationDateID,
    ListByCommunityEventReservationTimeID,
    ListByGtnAndCommunityEventID,
    ListByCommunityEventID,
    ExportByCommunityEventID,
    Pass,
    Reject,
};
