import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { WxampUserGroupUser } from './model.type';

const base_path = '/wxamp_user';

const getByAppID = (appid : string, q: PageQuery) => {
    return http.get(`${base_path}/get_by_appid/${appid}?${http.pageToUrlParams(q)}`)
}

export interface UserGroup  {
    group_id: Array<number>;
    user_id: Array<number>;
}
const addUserGroup = (data: UserGroup) => {
    return http.post(`${base_path}/add_user_group`, data);
}


export default {
    getByAppID,
    addUserGroup,
};
