
import http from './http';
import type { CommunityHomeContent } from './model.type';



const base_path = '/community_home_content';

const getByCommunityHomeID = (community_home_id : string) => {
    return http.get(`${base_path}/get_by_community_home_id/${community_home_id}`)
}

const update_sort = (data : CommunityHomeContent[]) => {
    return http.post(`${base_path}/update_sort`, {items: data})
}

export default {
    update_sort,
    getByCommunityHomeID,
};
