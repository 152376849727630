import http from './http';
import type { CommunityEvent } from './model.type';

// get_by_community_home_id/:community_home_id

const base_path = '/community_home';

const getByCommunityHomeID = (community_home_id : string) => {
    return http.get(`${base_path}/get_by_community_home_id/${community_home_id}`)
}

const UpdateRelation = (data : {
        community_home_id: number,
        ids: number[],
    }) => {
        return http.post(`${base_path}/update_relation`, data)

}

const update_sort = (data : CommunityEvent[]) => {
    return http.post(`${base_path}/community_event/update_sort`, {items: data})
}

export default {
    update_sort,
    UpdateRelation,
    getByCommunityHomeID
}