import http from './http';

import type { PageQuery, GetByID } from './http_types';

import type { CommunityEvent } from './model.type';

const base_path = '/community_event';

const CopyToType2 = (community_event_id: number = 0) => {
    return http.post(`${base_path}/copy_to_type2?community_event_id=${community_event_id}`,)
}

const UpdateReservationWxampUserGroupID = (ce: CommunityEvent) => {
    return http.post(`${base_path}/update_reservation_wxamp_user_group_id`, ce)
}

export default {
    CopyToType2,
    UpdateReservationWxampUserGroupID,
}