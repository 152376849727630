
import http from './http';

const base_path = "/wxamp";

const getByAppID = (appid: string) => {
    return http.get(`${base_path}/appid/${appid}`);

}




export default {
    getByAppID,
};